html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

:root{
  height: 100%;
  position: relative;
  --primary: #019DD1;
  --dark: #032142;
  --grey: #5F5F5F;
  --light-grey: #9D9D9D;
  --light: #F3F3F3;
  --input-border: #DBDFE2;
  --green: #449517;
  --concierge-dark: #30353F;
  --concierge-dark-gray: #838895;
}

body {
  height: 100%;
  margin: 0;
  background-color: #F7F7F7;
  padding: 0;
}

#root {
  height: 100%;
}

body, button {
  margin: 0;
  font-family: Inter, system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Animations */
@keyframes bounceIn {
  0% { 
     opacity: 0; 
     transform: scale(.3);
  }
  50% { 
     opacity: 1;
     transform: scale(1.05);
  }
  70% {
     transform: scale(.9);
  }
  100% {
     transform: scale(1);
  }
}

@keyframes slideUp {
  98% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}


@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #a9a9a9, 1.8em -1.8em 0 0em rgba(169,169,169, 0.2), 2.5em 0em 0 0em rgba(169,169,169, 0.2), 1.75em 1.75em 0 0em rgba(169,169,169, 0.2), 0em 2.5em 0 0em rgba(169,169,169, 0.2), -1.8em 1.8em 0 0em rgba(169,169,169, 0.2), -2.6em 0em 0 0em rgba(169,169,169, 0.5), -1.8em -1.8em 0 0em rgba(169,169,169, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(169,169,169, 0.7), 1.8em -1.8em 0 0em #a9a9a9, 2.5em 0em 0 0em rgba(169,169,169, 0.2), 1.75em 1.75em 0 0em rgba(169,169,169, 0.2), 0em 2.5em 0 0em rgba(169,169,169, 0.2), -1.8em 1.8em 0 0em rgba(169,169,169, 0.2), -2.6em 0em 0 0em rgba(169,169,169, 0.2), -1.8em -1.8em 0 0em rgba(169,169,169, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(169,169,169, 0.5), 1.8em -1.8em 0 0em rgba(169,169,169, 0.7), 2.5em 0em 0 0em #a9a9a9, 1.75em 1.75em 0 0em rgba(169,169,169, 0.2), 0em 2.5em 0 0em rgba(169,169,169, 0.2), -1.8em 1.8em 0 0em rgba(169,169,169, 0.2), -2.6em 0em 0 0em rgba(169,169,169, 0.2), -1.8em -1.8em 0 0em rgba(169,169,169, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(169,169,169, 0.2), 1.8em -1.8em 0 0em rgba(169,169,169, 0.5), 2.5em 0em 0 0em rgba(169,169,169, 0.7), 1.75em 1.75em 0 0em #a9a9a9, 0em 2.5em 0 0em rgba(169,169,169, 0.2), -1.8em 1.8em 0 0em rgba(169,169,169, 0.2), -2.6em 0em 0 0em rgba(169,169,169, 0.2), -1.8em -1.8em 0 0em rgba(169,169,169, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(169,169,169, 0.2), 1.8em -1.8em 0 0em rgba(169,169,169, 0.2), 2.5em 0em 0 0em rgba(169,169,169, 0.5), 1.75em 1.75em 0 0em rgba(169,169,169, 0.7), 0em 2.5em 0 0em #a9a9a9, -1.8em 1.8em 0 0em rgba(169,169,169, 0.2), -2.6em 0em 0 0em rgba(169,169,169, 0.2), -1.8em -1.8em 0 0em rgba(169,169,169, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(169,169,169, 0.2), 1.8em -1.8em 0 0em rgba(169,169,169, 0.2), 2.5em 0em 0 0em rgba(169,169,169, 0.2), 1.75em 1.75em 0 0em rgba(169,169,169, 0.5), 0em 2.5em 0 0em rgba(169,169,169, 0.7), -1.8em 1.8em 0 0em #a9a9a9, -2.6em 0em 0 0em rgba(169,169,169, 0.2), -1.8em -1.8em 0 0em rgba(169,169,169, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(169,169,169, 0.2), 1.8em -1.8em 0 0em rgba(169,169,169, 0.2), 2.5em 0em 0 0em rgba(169,169,169, 0.2), 1.75em 1.75em 0 0em rgba(169,169,169, 0.2), 0em 2.5em 0 0em rgba(169,169,169, 0.5), -1.8em 1.8em 0 0em rgba(169,169,169, 0.7), -2.6em 0em 0 0em #a9a9a9, -1.8em -1.8em 0 0em rgba(169,169,169, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(169,169,169, 0.2), 1.8em -1.8em 0 0em rgba(169,169,169, 0.2), 2.5em 0em 0 0em rgba(169,169,169, 0.2), 1.75em 1.75em 0 0em rgba(169,169,169, 0.2), 0em 2.5em 0 0em rgba(169,169,169, 0.2), -1.8em 1.8em 0 0em rgba(169,169,169, 0.5), -2.6em 0em 0 0em rgba(169,169,169, 0.7), -1.8em -1.8em 0 0em #a9a9a9;
  }
}